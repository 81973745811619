import React, {FC, useContext} from "react";
import {AppContext} from "../../../context/context";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {EditableValue} from "./CharacterArea";
import {PermissionModel} from "../../../shared_src/shared_types";


type CharacterAttributesType = {
    username: string
};
export const CharacterAttributes: FC<CharacterAttributesType> = ({username}) => {
    const {state} = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const attributes = character.attributes;
    if(!attributes){
        return null;
    }

    const permission: PermissionModel = {__typename: 'CharacterAttributes', username: username};

    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>Attribute</Card.Title>
                        <Card.Text>
                            St&auml;rke: <EditableValue target={permission} parentValue={attributes} keyId={"strength"} username={username}/><br/>
                            Geschick: <EditableValue target={permission} parentValue={attributes} keyId={"dexterity"} username={username}/><br/>
                            Mana: <EditableValue target={permission} parentValue={attributes} keyId={"mana"}
                                                 username={username}/> / <EditableValue target={permission} parentValue={attributes}
                                                                                        keyId={"maxmana"}
                                                                                        username={username}/><br/>
                            Lebenspunkte: <EditableValue target={permission} parentValue={attributes} keyId={"hp"}
                                                         username={username}/> / <EditableValue target={permission} parentValue={attributes}
                                                                                                keyId={"maxhp"}
                                                                                                username={username}/><br/>
                            Magischer Kreis: <EditableValue target={permission} parentValue={attributes} keyId={"magic"}
                                                            username={username}/><br/>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

