import React, { createContext, useReducer, Dispatch, useEffect } from "react";
import {
    VideoType,
    videoReducer,
    ReducerAction,
} from "./BackgroundReducer";

import {
    isMobile
} from "react-device-detect";
import { InitialStateType } from "./BackgroundReducer";
import { useCookies } from "react-cookie";
import { skoAnimatedBackground } from "../shared_src/shared_types";
import { Type } from "./ReducerTypes";

const initialState = {
    backgroundVideo: {
        isHidden: isMobile, //Hide on mobile devices
        time: 'day',
        weather: 'rain'
    } as VideoType
};

const BackgroundContext = createContext<{
    state: InitialStateType;
    dispatch: Dispatch<ReducerAction>;
}>({
    state: initialState,
    dispatch: () => null
});


const mainReducer = (
    { backgroundVideo }: InitialStateType, action: ReducerAction) => ({
        backgroundVideo: videoReducer(backgroundVideo, action),
    });

const BackgroundProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    const [cookies] = useCookies();
    const hideBackground = cookies[skoAnimatedBackground] === 'true' || isMobile;

    useEffect(() => {
        dispatch({ type: hideBackground ? Type.VideoHide : Type.VideoShow });
    }, [hideBackground]);

    return (
        <BackgroundContext.Provider value={{ state, dispatch }}>
            {children}
        </BackgroundContext.Provider>
    );
};

export { BackgroundProvider, BackgroundContext };
