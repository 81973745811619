import React, { useEffect, useContext, ReactText, FunctionComponent } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { fetchData } from "../api/handler";
import { ApiPlayerListType, PlayerType } from "../shared_src/shared_types";
import { Type } from "../context/ReducerTypes";
import { useTranslation } from "react-i18next";
import { PlayerlistContext } from "../context/PlayerlistContext";
type WeatherStatus = {
    status?: string,
    color?: string,
};

export enum Weather {
    Thunderstorm = -3,
    HeavyRain,
    Rain,
    Cloudy,
    SlightlyCloudly,
    ClearSky,
    SultryClearSky,
}


const GetWeatherStatus = (weather: number, hour: number) => {
    const { t } = useTranslation();
    const isNight = hour > 21 || hour < 3;
    const isDawn = hour > 18 || hour < 6;
    let result = {} as WeatherStatus;
    switch (weather) {
        case Weather.Thunderstorm:
            result.status = t("weather.day.Thunderstorm");
            result.color = 'rgb(0,0,200)';
            break;
        case Weather.HeavyRain:
            result.status = t("weather.day.HeavyRain");
            result.color = 'rgb(0,64,255)';
            break;
        case Weather.Rain:
            result.status = t("weather.day.Rain");
            result.color = 'rgb(0,128,255)';
            break;
        case Weather.Cloudy:
            result.status = t("weather.day.Cloudy");
            result.color = 'rgb(255,255,255)';
            break;
        case Weather.SlightlyCloudly:
            result.status = isNight ? t("weather.day.SlightlyCloudly") : isDawn ? t("weather.dawn.SlightlyCloudly") : t("weather.day.SlightlyCloudly");
            result.color = isNight ? 'rgb(128,128,128)' : isDawn ? 'rgb(255,255,255)' : 'rgb(185,185,50)';
            break;
        case Weather.ClearSky:
            result.status = isNight ? t("weather.night.ClearSky") : isDawn ? t("weather.dawn.ClearSky") : t("weather.day.ClearSky");
            result.color = isNight ? 'rgb(104,92,241)' : isDawn ? 'rgb(104,92,241)' : 'rgb(228,211,0)';
            break;
        case Weather.SultryClearSky:
            result.status = isNight ? t("weather.night.SultryClearSky") : isDawn ? t("weather.dawn.SultryClearSky") : t("weather.day.SultryClearSky");
            result.color = isNight ? 'rgb(50,35,237)' : isDawn ? 'rgb(104,92,241)' : 'rgb(255,52,16)';
            break;
    }
    return <span style={{ color: result.color }}>
        {result.status}
    </span>
};

interface ServerIsOnlineCheckType extends React.PropsWithChildren {
    isOnline: boolean,
};

const ServerIsOnline: FunctionComponent<ServerIsOnlineCheckType> = ({ isOnline, children }) => {
    if (isOnline) {
        return <>{children}</>
    }
    return null;
};

const adminColor = 'rgb(190,70,185)';
const leitenderSupportColor = 'rgb(231, 113, 47)';
const supporterColor = 'rgb(255, 174, 59)';
const eventleiterColor = 'rgb(120, 120, 255)';
const eventmanagerColor = 'rgb(61,61,243)';
const servermoderatorColor = 'rgb(255, 50, 50)';
const loggedInColor = '#fff';
const notLoggedInColor = '#ffd700';

const PlayerList = () => {

    const { state, dispatch } = useContext(PlayerlistContext);
    useEffect(() => {
        const fetchPlayers = async () => {
            const playerListFetch = await fetchData('/players');
            if (playerListFetch.success) {
                const data: ApiPlayerListType = playerListFetch.data;
                dispatch({
                    type: Type.PlayerListRefresh,
                    payload: data,
                });
            } else {
                dispatch({
                    type: Type.PlayerListReset
                });
            }
        };
        fetchPlayers();
        let interval = 60;
        if (process.env.REACT_APP_PLAYERLIST_REFRESHRATE) {
            interval = parseFloat(process.env.REACT_APP_PLAYERLIST_REFRESHRATE);
        }
        setInterval(fetchPlayers, interval * 1000);
    }, [dispatch]);



    const tableList = state.playerList.players.map((player: PlayerType) => {

        let playerColor;
        //Info: 0 = nicht eingeloggt, ansonsten Adminrang+1 = eingeloggt. Kein Admin = 1, Supp = 2, Spielleiter = 3...
        switch (player.status) {
            case 0:
                playerColor = notLoggedInColor;
                break;
            case 1:
                playerColor = loggedInColor;
                break;
            case 2:
                playerColor = adminColor;
                break;
            case 3:
                playerColor = leitenderSupportColor;
                break;
            case 4:
                playerColor = eventleiterColor;
                break;
            case 5:
                playerColor = supporterColor;
                break;
            case 6:
                playerColor = eventmanagerColor;
                break;
            case 7:
                playerColor = servermoderatorColor;
                break;
            default:
                playerColor = notLoggedInColor;
                break;
        }

        return (
            <tr key={`${player.playerId}_${player.playername}`} style={{ color: playerColor }}>
                <td>{player.playerId}</td>
                <td>{player.playername}</td>
            </tr>
        );
    });

    const hour: string = (state.playerList.hour < 10 ? '0' + state.playerList.hour : state.playerList.hour).toString();
    const minute: string = (state.playerList.minute < 10 ? '0' + state.playerList.minute : state.playerList.minute).toString();

    return (
        <>
            <Card className={'bg-sko-box'}>
                <Card.Header as="h5">{state.playerList.servername}</Card.Header>
                <Card.Body>
                    <Card.Text>
                        IP-Adresse: 159.69.112.141:46502<br />
                        Status: {state.playerList.isOnline ? <span style={{ color: 'rgb(30,255,30)' }}>ONLINE</span> : <span style={{ color: 'rgb(255,30,30)' }}>OFFLINE</span>}<br />
                        <ServerIsOnline isOnline={state.playerList.isOnline}>
                            Spieler: {state.playerList.playerAmount} / {state.playerList.playerMax}<br />
                            Spielerrekord: {state.playerList.playerRecord}<br />
                            <br />
                            {hour}:{minute} Uhr | {GetWeatherStatus(state.playerList.weather, state.playerList.hour)}
                        </ServerIsOnline>
                    </Card.Text>
                    <ServerIsOnline isOnline={state.playerList.isOnline}>
                        <Table striped bordered={true} hover size={"sm"}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.playerList.players.length > 0 ? tableList : 'Momentan ist kein Spieler online.'}
                            </tbody>
                        </Table>
                    </ServerIsOnline>
                </Card.Body>
            </Card>
        </>
    );
};



export default () => {
    return (
        <>
            <PlayerList />
        </>
    );
};
