import React from "react";

import Figure from "react-bootstrap/Figure";
import Container from "react-bootstrap/Container";
export default () => {

  return (
      <>
          <Container>
              <Figure>
                  <Figure.Image
                      alt="Karte der Außenwelt"
                      src="https://www.strafkolonie-online.net/aussenwelt/"
                  />
                  <Figure.Caption>
                      Ein Karte der Außenwelt.
                  </Figure.Caption>
              </Figure>
          </Container>
      </>
  );
};