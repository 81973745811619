import React, { useEffect} from "react";
import PlayerListContainer from '../playerlist';



export default () => {



    useEffect(() => {
       // console.log(fetchPlayers());
        //setPlayerlist(result);
    });


    return (
        <PlayerListContainer/>

    );
};