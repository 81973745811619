import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import BackgroundVideo from "./components/BackgroundVideo";
import { AppProvider } from "./context/context";
import { BackgroundProvider } from "./context/BackgroundContext";
import { PlayerlistProvider } from "./context/PlayerlistContext";
import { render } from '@testing-library/react';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppProvider>
    <BackgroundProvider>
        <PlayerlistProvider>
            <BackgroundVideo />
            <App />
        </PlayerlistProvider>
    </BackgroundProvider>
</AppProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
