import React, { createRef, FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/context";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Type } from "../../../context/ReducerTypes";
import { Weather } from "../../playerlist";
import { ApiUserDataType, PermissionModel } from "../../../shared_src/shared_types";
import { fetchData } from "../../../api/handler";
import AccountArea from "./AccountArea";
import { CharacterArea } from "./CharacterArea";
import Form from "react-bootstrap/Form";
import { PlayerlistContext } from "../../../context/PlayerlistContext";
import { AdminLivemapModal } from "./AdminLivemap";
import { Can } from "./WebinterfacePermissions";
import Row from "react-bootstrap/Row";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart, ChartOptions, registerables, scales } from "chart.js";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import toDateString from "../../../utils/toDateString";
import { de } from 'date-fns/locale';

ChartJS.register(
    ...registerables
);

export const AdminArea = () => {

    return (
        <>
            Dies ist der Adminbereich.
            <DebugButtons />
        </>);

};

export default AdminArea;

type ModalType = {
    onHide: any,
    show: boolean,
    username: string
};

const UserDataModal: FC<ModalType> = (props) => {
    const username = props.username;
    const { state } = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    //const character = state.account.characters[username];

    return (
        <Modal
            {...props}
            centered
            className={"bg-sko-modal"}
            dialogClassName={"bg-sko-modal-60w"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Charakterdaten
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AccountArea username={username} />
                <CharacterArea username={username} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


const DebugButtons = () => {

    const { dispatch } = useContext(AppContext);
    const { state: playerlistState, dispatch: playerlistDispatch } = useContext(PlayerlistContext);
    const [userdataModalShow, setUserdataModalShow] = React.useState(false);
    const [livemapModalShow, setLivemapModalShow] = React.useState(false);
    const [character, setCharacter] = React.useState<undefined | string>(undefined);
    const accessUsername: React.RefObject<HTMLInputElement> = createRef();

    const accountData: PermissionModel = { __typename: 'CharacterAchievements', username: character || '' };



    return (
        <>
            <Can I='read' this={accountData}>
                <Form.Group controlId="user">
                    <Form.Label>Charaktername</Form.Label>
                    <Form.Control required ref={accessUsername} type="text" placeholder="Charaktername" />
                </Form.Group>
                <Button onClick={async () => {
                    const user = {
                        username: accessUsername?.current?.value,
                    };
                    const userData = await fetchData('/getUserData', 'POST', user);
                    if (userData.success) {
                        const data = (userData as ApiUserDataType).data;
                        dispatch({
                            type: Type.AccountSetCharacter,
                            payload: data,
                        });
                        setCharacter(data?.username);
                        setUserdataModalShow(true);
                    }
                }}>Lade Accountdaten</Button>

                {character ? <UserDataModal
                    show={userdataModalShow}
                    onHide={() => setUserdataModalShow(false)}
                    username={character}
                /> : null}

            </Can>
            <Can I='see' an='AdminLivemap'>
                <Button onClick={async () => {
                    setLivemapModalShow(true);
                }}>Öffne Livemap</Button>
                {livemapModalShow ? <AdminLivemapModal
                    show={livemapModalShow}
                    onHide={() => setLivemapModalShow(false)}
                /> : null}
            </Can>
            <Can I='see' a='AdminServerStatistics'>
                <hr />
                <h3>Serverstatistik</h3>
                <hr />
                <AdminStats />
            </Can>
        </>
    );
};

type PlayerChartData = {
    data: any,
    title: string,
    interval: number,
    hours: boolean,
};

const day = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

const PlayerChart: React.FC<PlayerChartData> = ({ data, title, interval, hours }) => {

    const chartData = {
        datasets: [
            {
                label: hours ? `In den letzen 24 Stunden` : `${title} ${interval} Tage`,
                data: data.map((item: { timestamp: string | number | Date; playercount: any; }) => ({
                    x: new Date(item.timestamp),
                    y: item.playercount
                })),
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',

            }
        ],
        legend: {
            labels: {
                color: "red",
                font: {
                    size: 18
                },
            },
        },
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: title,
                },
            },
            x: {
                beginAtZero: true,
                type: 'timeseries',
                time: {
                    unit: hours ? 'hour' : 'day',
                    stepSize: 1,
                    tooltipFormat: 'HH:mm',
                    displayFormats: {
                        hour: 'HH:mm'
                    }
                },
                title: {
                    display: true,
                    text: hours ? 'Stunde' : 'Datum'
                },
                tension: 1,

            },

        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const dayOfWeek = new Date(data[context.dataIndex].timestamp).getDay()
                        return data[context.dataIndex].playercount + ' Spieler am ' + day[dayOfWeek] + ", " + toDateString(data[context.dataIndex].timestamp);
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: true
    };
    //@ts-ignore 
    return <Line data={chartData} options={options} />;
};

type PlayerPieChartData = {
    last7Days: number,
    last30Days: number,
};

const PlayerPieChart: React.FC<PlayerPieChartData> = ({ last7Days, last30Days }) => {
    const data = {
        labels: ['Letzten 7 Tage', 'Letzten 30 Tage'],
        datasets: [{
            label: `Charakteranzahl`,
            data: [last7Days, last30Days],
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
        }
    };

    return <Bar data={data} options={options} />;
};


const AdminStats = () => {
    const [loading, setLoading] = useState(0);
    const [stats, setStats] = useState({
        playerCountDataYesterday: null,
        playerCountData7Days: null,
        playerCountData30Days: null,
        loggedInPlayersLast7Days: null,
        loggedInPlayersLast30Days: null,
        registeredPlayers30Days: null
    });

    useEffect(() => {
        const loadStats = async () => {
            try {
                const stats = await fetchData('/getstats', 'GET');

                setStats({
                    playerCountDataYesterday: stats.data.playerCountDataYesterday,
                    playerCountData7Days: stats.data.playerCountData7Days,
                    playerCountData30Days: stats.data.playerCountData30Days,
                    loggedInPlayersLast7Days: stats.data.loggedInPlayersLast7Days,
                    loggedInPlayersLast30Days: stats.data.loggedInPlayersLast30Days,
                    registeredPlayers30Days: stats.data.registeredPlayers30Days
                });
                setLoading(1)
            } catch (err) {
                setLoading(2)
                console.log(err)
            }
        };
        loadStats();
    }, []);


    return (
        <>
            {(loading === 1 ? <>
                <PlayerChart title={'Spieleranzahl'} data={stats.playerCountDataYesterday} interval={0} hours={true} />
                <PlayerChart title={'Spieleranzahl'} data={stats.playerCountData7Days} interval={7} hours={false} />
                <PlayerChart title={'Spieleranzahl'} data={stats.playerCountData30Days} interval={30} hours={false} />
                <PlayerPieChart last7Days={stats.loggedInPlayersLast7Days!} last30Days={stats.loggedInPlayersLast30Days!} />
                <PlayerChart title={'Neuzugänge'} data={stats.registeredPlayers30Days} interval={30} hours={false} />
            </> : (loading === 0 ? <><p>Lade Daten...</p></> : <><p>Die Daten konnten nicht geladen werden. Bitte probiere es später erneut.</p></>))
            }
        </>
    );
};