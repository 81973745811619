import React, { FC, useContext, useState, useEffect } from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { AppContext } from "../../../context/context";
import LoginComponent from '../../login';
import LogoutComponent from '../../logout';
import loadingSpinner from '../../../images/favicon.png'
import mudLoading from '../../../images/mud.png'
import AdminArea from "./AdminArea";
import LogManagementArea from "./LogManagementArea";
import CharacterArea from "./CharacterArea";
import AccountArea from "./AccountArea";
import { AbilityContext, Can } from './WebinterfacePermissions';
import { PermissionModel } from "../../../shared_src/shared_types";
import { useLocation, Link, useNavigate } from 'react-router-dom'


interface TabNavProps {
    link: string,
    text: string,
}

const WebinterfaceOverview: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routePath = location.pathname
    const { state } = useContext(AppContext);



    const [tab, setTab] = useState('/roleplay');
    const { isLoggedIn, characters, username, ability } = state.account;
    if (!isLoggedIn || !characters || !username) {
        return <></>
    }

    useEffect(() => {
        if (routePath == '/webinterface' || routePath == '/webinterface/') {
            navigate('/webinterface/roleplay', { replace: true });
        }
    }, []);

    const accountData: PermissionModel = { __typename: 'AccountData', username: username };
    const characterData: PermissionModel = { __typename: 'CharacterData', username: username };

    const TabNav = (props: TabNavProps) => <Nav.Item>
        <Nav.Link as={Link}
            onSelect={(event) => {
                event.preventDefault()
                setTab(props.link);
            }}
            to={props.link}
            eventKey={props.link}
        >
            {props.text}
        </Nav.Link>
    </Nav.Item>;


    let tabContent;
    switch (routePath) {
        case '/webinterface/roleplay':
            tabContent = <CharacterArea username={username} />;
            break;
        case '/webinterface/ooc':
            tabContent = <AccountArea username={username} />;
            break;
        case '/webinterface/logmanagement':
            tabContent = <LogManagementArea />;
            break;
        case '/webinterface/admin':
            tabContent = <AdminArea />;
            break;
    }
    return (
        <>
            <AbilityContext.Provider value={ability}>
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey={routePath}>
                        <Can I='read' this={characterData}>
                            <TabNav link={'/webinterface/roleplay'} text={'Charakterdaten'} />
                        </Can>
                        <Can I='read' this={accountData}>
                            <TabNav link={'/webinterface/ooc'} text={'Accountdaten'} />
                        </Can>
                        <Can I='see' a='LogArea'>
                            <TabNav link={'/webinterface/logmanagement'} text={'Logverwaltung'} />
                        </Can>
                        <Can I='see' an='AdminArea'>
                            <TabNav link={'/webinterface/admin'} text={'Adminbereich'} />
                        </Can>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {tabContent}
                </Card.Body>
            </AbilityContext.Provider>
        </>
    );

};


export const Webinterface = () => {
    const { state: { account: { checkingLogin, isLoggedIn } } } = useContext(AppContext);
    return (
        <>

            {
                checkingLogin ?
                    (
                        <>
                            <Container>
                                <Row className={'justify-content-md-center'}>
                                    <img src={loadingSpinner} alt={'loading'} className={'skoLoading'} />
                                    <img src={mudLoading} alt={'loading'} className={'skoLoadingMud'} />
                                </Row>
                            </Container>
                        </>
                    )
                    : !isLoggedIn ?
                        (
                            <>
                                <LoginComponent />
                            </>
                        )
                        :
                        (
                            <>
                                <WebinterfaceOverview />
                                <LogoutComponent />
                            </>
                        )
            }
        </>
    );
};
export default Webinterface;