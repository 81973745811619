import {
    PlayerType,
    ServerStatsDataType,
} from "../shared_src/shared_types";
import { Type } from "./ReducerTypes";



export type InitialStateType = {
    playerList: ServerStatsDataType;
};

export type ReducerAction = {
    type: string,
    payload?: any | any[]
}



export const playerListReducer = (state: ServerStatsDataType, action: ReducerAction) => {
    switch (action.type) {
        case Type.PlayerListReset:
            return { ...state, players: [] as PlayerType[] };
        case Type.PlayerListRefresh:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};