import React from 'react';
import logo from '../../images/logo.png';
import Container from "react-bootstrap/Container";


const Header = () => {
    return (
        <>
            <Container fluid id={'header'} className={'text-center'}>
                <img src={logo} className="img-fluid" alt='Strafkolonie Online Logo'/>
            </Container>
        </>
    );

};


export default Header;