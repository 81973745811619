import Card from "react-bootstrap/Card";
import React, { FC, useContext } from "react";
import { AppContext } from "../../../context/context";
import toDateString from "../../../utils/toDateString";
import { EditableValue } from "./CharacterArea";
import { PermissionModel } from "../../../shared_src/shared_types";



type AccountAreaType = {
    username: string,
}

export const AccountArea: FC<AccountAreaType> = ({ username }) => {
    const { state } = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];

    const adminLevelEditPermission: PermissionModel = { __typename: 'CharacterAdminLevel', username: username };
    return (
        <>
            <Card className={'bg-sko sko-card-height-full'}>
                <Card.Body>
                    <Card.Title>Accountdaten</Card.Title>
                    <Card.Text>
                        Charakter: {character.username}<br />
                        Registriert seit dem: {toDateString(character.firstLogin)}<br />
                        Letzter Login: {toDateString(character.lastLogin)}<br />
                        Facechange: {character.facechange ? 'Verfügbar' : 'Verbraucht'}<br />
                        <br />
                        Körpertextur ID: {character.characterVisual?.bodyTextureId}<br />
                        Kopfmesh: {character.characterVisual?.headMesh}<br />
                        Gesichtstexur ID: {character.characterVisual?.faceTextureId}<br />
                        Statur: {character.characterVisual?.fatness}<br />
                        Schulterbreite: {character.characterVisual?.shoulderWidth}<br />
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
};

export default AccountArea;