import React, { createContext, useReducer, Dispatch } from "react";
import {
    ReducerAction,
    InitialStateType,
    AccountType,
    accountReducer, UserDataList,
} from "./reducers";

import { PermissionAbilities } from "../shared_src/shared_types";
import { Ability } from "@casl/ability";


const initialState = {
    account: {
        isLoggedIn: false,
        checkingLogin: true,
        username: null,
        ability: new Ability<PermissionAbilities>(),
        characters: {} as UserDataList
    } as AccountType
};

const AppContext = createContext<{
    state: InitialStateType;
    dispatch: Dispatch<ReducerAction>;
}>({
    state: initialState,
    dispatch: () => null
});



const mainReducer = (
    { account }: InitialStateType, action: ReducerAction) => ({
        account: accountReducer(account, action),
    });

const AppProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
