import React, { useContext, useState } from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Container, Col, Row } from "react-bootstrap";
import { AppContext } from "../context/context";
import { fetchData } from "../api/handler";
import { Type } from "../context/ReducerTypes";
import { ApiLoginDataType } from "../shared_src/shared_types";

const fetchLogin = (data: {}) => {
    return fetchData('/login', 'POST', data);
};



export default () => {
    const { dispatch } = useContext(AppContext);

    const [logginIn, setLogginIn] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const onEnterPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            const userComp = (document.getElementById('user') as HTMLInputElement);
            const pwComp = (document.getElementById('password') as HTMLInputElement);

            if (pwComp.validity.valid && userComp.validity.valid) {
                loginFunc();
                return;
            }
            pwComp.reportValidity();
            userComp.reportValidity();
            return;
        }
    };

    const loginFunc = async () => {
        const userComp = (document.getElementById('user') as HTMLInputElement);
        const pwComp = (document.getElementById('password') as HTMLInputElement);
        const stayLoggedInComp = (document.getElementById('stayLoggedIn') as HTMLInputElement);

        if (!pwComp.validity.valid || !userComp.validity.valid) {
            pwComp.reportValidity();
            userComp.reportValidity();
            return;
        }

        setLogginIn(true);
        const username: string = userComp.value;
        const pw: string = pwComp.value;

        const userLogin = {
            username: username,
            password: pw,
            stayLoggedIn: stayLoggedInComp.checked
        };
        const res: ApiLoginDataType = await fetchLogin(userLogin);
        setLogginIn(false);
        if (res.error) { //Verbindungsfehler
            setLoginError(true);
            setLoginErrorMessage(res.error.message);
            return;
        }
        if (res.success) {
            setLoginError(false);
            setLoginErrorMessage('');
            dispatch({
                type: Type.AccountLogin, payload: res.data
            });
            return;
        }


    };

    return (
        <>
            <Card.Body >
                <Card.Title>Login</Card.Title>
                <Container>
                    <Row>
                        <Col>
                            <Form >
                                {loginError ?
                                    <Alert variant={'danger'}>
                                        {loginErrorMessage}
                                    </Alert>
                                    :
                                    ''
                                }
                                <Form.Group controlId="user" className="mb-3">
                                    <Form.Label>Charaktername</Form.Label>
                                    <Form.Control required onKeyPress={onEnterPress} isInvalid={loginError} type="text" placeholder="Charaktername" />
                                </Form.Group>

                                <Form.Group controlId="password" className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required onKeyPress={onEnterPress} isInvalid={loginError} type="password" placeholder="Password" />
                                </Form.Group>
                                <Form.Group controlId='stayLoggedIn' className="mb-3">
                                    <Form.Check type="checkbox" label="Angemeldet bleiben" />
                                </Form.Group>

                                <Button variant="primary" type="button" disabled={logginIn} onClick={loginFunc}>
                                    {!logginIn ?
                                        <>Login</> :
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Lade...
                                        </>
                                    }
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </>
    );
};

