import React, {FC, useContext, useState} from "react";
import {AppContext} from "../../../context/context";
import {AchievementType} from "../../../shared_src/shared_types";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import toDateString from "../../../utils/toDateString";
import Col from "react-bootstrap/Col";


type CharacterAchievementsType = {
    username: string,
};
const AchievementListItem = ({name, date, description}: AchievementType) => {

    const parts: any[] = description.split(/\\n/gi);
    for (let i = 1; i < parts.length; i += 2) {
        parts[i] = <p key={'desciption'+i}>{parts[i]}</p>;
    }

    return (
        <tr>
            <td>{name}</td>
            <td>
                Freigeschaltet am {toDateString(date)}<br/>
                {parts}
            </td>
        </tr>
    );

};

export const CharacterAchievements: FC<CharacterAchievementsType> = ({username}) => {

    const {state} = useContext(AppContext);
    const [search, setSearch] = useState('');
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const achievements = character.achievements;
    if(!achievements){
        return null;
    }
    const achievementList = achievements.map((achievement: AchievementType) =>
        {
            if(achievement.name.toLowerCase().includes(search.toLowerCase()) || search === ''){
                return (<AchievementListItem key={`${achievement.name}`} name={achievement.name} description={achievement.description} date={achievement.date}/>)
            }
            return null;
        }
    );


    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>
                            Erfolge{' '}
                            <input type="text" placeholder={"Suche"} onChange={(event) => {
                                setSearch(event.target.value);
                            }}/>
                        </Card.Title>
                        <div className={'sko-card-inventory-scroll'}>
                            <Table striped bordered={true} hover size={"sm"}>
                                <thead>
                                <tr>
                                    <th>Erfolg</th>
                                    <th>Beschreibung</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {achievementList}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};