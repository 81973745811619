import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from './locals/de.trans.json';

// the translations
const resources = {
    de: {
        translation: translationDE
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "de",


        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;