import {PermissionAbilities, UserDataType, UserLoginType} from "../shared_src/shared_types";
import {Type} from "./ReducerTypes";
import {Ability, detectSubjectType} from "@casl/ability";


export type AccountType = {
    isLoggedIn: boolean,
    checkingLogin: boolean,
    username: string | null,
    ability: Ability<PermissionAbilities>,
    characters: UserDataList | null
};

export type UserDataList = {
    [key: string]: UserDataType
};

export type InitialStateType = {
    account: AccountType;
};

export type ReducerAction = {
    type: string,
    payload?: any | any[]
}

const permissionSubjectChecker = (subject: any) => {
    if (subject && typeof subject === 'object' && subject.__typename) {
        return subject.__typename;
    }

    return detectSubjectType(subject);
}
const options = { detectSubjectType: permissionSubjectChecker };


export const accountReducer = (state: AccountType, action: ReducerAction) => {
    switch (action.type) {
        case Type.AccountLogin:
            if (action.payload != null) {
                const payload: UserLoginType = action.payload;
                const username = payload.userData.username;
                const permissions = payload.permissionData;
                return {
                    ...state,
                    isLoggedIn: true,
                    checkingLogin: false,
                    username: username,
                    ability: new Ability<PermissionAbilities>(permissions, options),
                    characters: {
                        ...state.characters,
                        [username]: payload.userData,
                    },
                };
            }
            return {...state, isLoggedIn: true};
        case Type.AccountLogout:
            return {...state, characters: null, ability: new Ability<PermissionAbilities>([], options), isLoggedIn: false, username: null};
        case Type.AccountStopCheckLogin:
            return {...state, checkingLogin: false};
        case Type.AccountSetCharacter:
            const payload: UserDataType = action.payload;
            const username = payload.username;
            return {
                ...state,
                characters:{
                    ...state.characters,
                    [username]: payload,
                }
            };
        default:
            return state;
    }
};
