import React from "react";

import Card from "react-bootstrap/Card";
import artwork_sko_logo_1 from '../../images/artwork/sko_logo_1.png';
import artwork_sko_logo_2 from '../../images/artwork/sko_logo_2.png';
import artwork_sko_loading_1 from '../../images/artwork/sko_loadingscreen_1.png';
import artwork_sko_loading_2 from '../../images/artwork/sko_loadingscreen_2.png';
import artwork_al1 from '../../images/artwork/al1.png';
import artwork_austauschplatz1 from '../../images/artwork/awh1.png';
import artwork_blutfliege_koenig from '../../images/artwork/blutfliege.png';
import artwork_cave1 from '../../images/artwork/cave1.png';
import artwork_cave2 from '../../images/artwork/cave2.png';
import artwork_karte1 from '../../images/artwork/karte1.png';
import artwork_koenig from '../../images/artwork/koenig1.png';
import artwork_paladine1 from '../../images/artwork/paladine1.png';
import artwork_paladine2 from '../../images/artwork/paladine2.png';
import artwork_pass1 from '../../images/artwork/pass1.png';
import artwork_pass2 from '../../images/artwork/pass2.png';
import Container from "react-bootstrap/Container";

type ArtworkType = {
    description: string,
    resource: string,
};

const ArtworksCollection: ArtworkType[] = [] as ArtworkType[];

ArtworksCollection.push({
    description: 'Das originale ursprüngliche SKO Logo',
    resource: artwork_sko_logo_1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Kolonie bei Tag (Öl) Loadingscreen Entwurf',
    resource: artwork_sko_loading_2,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Konvoi im Alten Lager',
    resource: artwork_al1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Austauschplatz',
    resource: artwork_austauschplatz1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Blutfliege mit Krone',
    resource: artwork_blutfliege_koenig,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Höhle 1',
    resource: artwork_cave1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Höhle 2',
    resource: artwork_cave2,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Karte von Myrtana',
    resource: artwork_karte1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'König Rhobar der II',
    resource: artwork_koenig,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Paladine 1',
    resource: artwork_paladine1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Paladine 2',
    resource: artwork_paladine2,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Gefangenenkonvoi 2',
    resource: artwork_pass1,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Gefangenenkonvoi 2',
    resource: artwork_pass2,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Eine weitere Version des Logos',
    resource: artwork_sko_logo_2,
} as ArtworkType);
ArtworksCollection.push({
    description: 'Kolonie Loadingscreen mit Figuren Entwurf',
    resource: artwork_sko_loading_1,
} as ArtworkType);

const ArtworkItem = ({ description, resource }: ArtworkType) => {

    return (
        <Card className={'bg-sko-box'} >
            <Card.Img variant="top" src={resource} />
            <Card.Body>
                <Card.Text>
                    {description}
                </Card.Text>
            </Card.Body>
        </Card>
    );

}

export default () => {

    const artworks = ArtworksCollection.map(value =>
        <><ArtworkItem key={value.resource} resource={value.resource} description={value.description} /><hr /></>
    );

    return (
        <>
            <Container>
                <Card className={'bg-sko'}>
                    <Card.Body>
                        <Card.Title>Artwork</Card.Title>
                        <Card.Text>Hier befinden sich Grafiken welche während der Entwicklung Enstanden sind und es teilweise leider nicht ins Spiel geschafft haben.</Card.Text>
                    </Card.Body>
                </Card>
                {artworks}
            </Container>
        </>
    );
}