import {ApiJSONType} from "../shared_src/shared_types";

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_DEV_URL;


export const fetchData = async (endpoint: string, method = 'GET', data: null | any = null): Promise<ApiJSONType>  => {
    method = (method === null || method === undefined) ? 'GET' : method;

    const fetchResult = await fetch(`${apiUrl}${endpoint}`,{
        method: method,
        credentials: 'include',
        referrerPolicy: 'origin-when-cross-origin',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: data !== null ? JSON.stringify(data) : null,
    }).then((response) => {
        return response.json();
    }).catch((e) => {

    });

    return fetchResult ? fetchResult : {
        success: false,
        error: {
            code: 503,
            message: 'Das Webinterface ist vorübergehend nicht erreichbar.'
        }
    } as ApiJSONType;
};


