import React from 'react';
import Card from "react-bootstrap/Card";


export default () => {
    return (
        <>
            <Card.Body>
                <h5>Strafkolonie Online</h5>
                <hr />
                <div style={{ textAlign: 'center' }}>
                    <iframe width="80%" height="480" src="https://www.youtube.com/embed/0pMESXY16ws?si=y1ipAaOtLzXJfmGJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
                <Card.Text>
                    Strafkolonie Online ist ein - auf der Lore des ersten Teils der Gothic Trilogie basierender - Online-Roleplay-Server, dessen Fokus auf der Interaktion zwischen den Spielern sowie das dadurch entstehende Roleplay richtet.<br />
                    Ob Buddler, Bandit, Templer oder Erzbaron – hinter jedem Charakter, den du auf dem Server triffst, steckt ein anderer Spieler, welcher sich seine ganz eigene Story für seinen Charakter ausgedacht hat und somit die Geschichte der Strafkolonie von Khorninis mitgestaltet.<br />
                    Umgeben von einer magischen Barriere, welche eine Flucht unmöglich macht, wird in der sowohl berüchtigten als auch gefürchteten Minenkolonie von den Gefangenen des gesamten Reiches Erz für den König gefördert, welcher damit Waffen für seine Armee im Krieg gegen die Orks herstellt.<br />
                    <br />
                    DU bist einer dieser Gefangenen!<br /><br />
                    Verurteilt zur lebenslangen Arbeit in den Erzminen von Khorinis, findest du deinen Weg in die berühmte Strafkolonie.<br />
                    Findest du dich mit deinem Schicksal ab und machst das Beste aus deiner misslichen Lage oder setzt du alles daran, einen Weg in die Freiheit zu finden?<br />
                    Diese Entscheidung liegt nun in deiner Hand. Schreibe deine eigene Geschichte, als Verurteilter Gefangener.<br /><br />
                </Card.Text>
                <h5>Features</h5>
                <hr />
                <div style={{ textAlign: 'center' }}>
                    <iframe width="80%" height="480" src="https://www.youtube.com/embed/-bjdDPu8-lQ?si=Gat_6opADKZ4LGRU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
                <Card.Text>
                    Über mehr als 10 Jahre haben wir unzählige Features ausgearbeitet und federführend in die Gothic Multiplayer Landschaft integriert und somit als Standard und Norm etabliert. Darunter unteranderem:
                </Card.Text>
                <ul>
                    <li>Persistente Spielwelt</li>
                    <li>Berufssysteme
                        <ul>
                            <li>Waffenschmied, Jäger, Kräuterkundler, Alchemist, Schreiner, Bogner und viele mehr!</li>
                        </ul>
                    </li>
                    <li>Gildensystem
                        <ul>
                            <li>Schatten, Gardisten, Feuermagier und Erzbaron</li>
                            <li>Banditen, Söldner und Wassermagier</li>
                            <li>Novizen, Templer und Gurus</li>
                        </ul>
                    </li>
                    <li>Truhensystem</li>
                    <li>Attributs und Skillsystem
                        <ul>
                            <li>Hunderte eindeutige Fähigkeiten</li>
                            <li>Dutzende Berufe</li>
                            <li>Ein ausgeprägtes und bewährtes Lehrsystem, basierend auf mehr als 10 Jahren Erfahrung</li>
                        </ul>
                    </li>
                    <li>Ambiente Konsum von Items</li>
                    <li>Soundsystem</li>
                    <li>Animationssystem</li>
                    <li>Synchrones Wettersystem</li>
                    <li>Einzigartiges und bisher einmaliges Jahreszeitensystem</li>
                    <li>Speziell geprüfte und gewartete SKO Mods (HD-Texture Packs)</li>
                    <li>Hunger- und Erschöpfungssystem</li>
                    <li>Monster mit eigenständiger AI und eigenen Tagesabläufen</li>
                    <li>Außenweltkarte (Simulation der Situation auf dem Festland)</li>
                </ul>

            </Card.Body >
        </>
    );
};

