import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Sidebar from "./layouts/sidebar";
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import Webinterface from "./pages/webinterface/Webinterface";
import Worldmap from "./pages/Worldmap";
import Artwork from "./pages/Artwork";
import { Routes, Route } from "react-router-dom";

const Content = () => {
    return (
        <>
            <Row lg={{ cols: 1 }} className="g-0">
                <Col lg={9} className="g-0">
                    <Card className={'bg-sko'}>
                        <Routes>
                            <Route path={'/'} Component={Home} />
                            <Route path={'/webinterface'} Component={Webinterface} />
                            <Route path={'/webinterface/roleplay'} Component={Webinterface} />
                            <Route path={'/webinterface/ooc'} Component={Webinterface} />
                            <Route path={'/webinterface/logmanagement'} Component={Webinterface} />
                            <Route path={'/webinterface/admin'} Component={Webinterface} />
                            <Route path={'/aussenwelt'} Component={Worldmap} />
                            <Route path={'/artwork'} Component={Artwork} />
                            <Route path={'/imprint'} Component={Imprint} />
                            <Route path={'*'} Component={Home} />
                        </Routes>
                    </Card>
                </Col>
                <Col lg={3} className="g-0">
                    <Sidebar />
                </Col>
            </Row>
        </>
    );
};

export default Content;