import React, {FC, useContext, useState} from "react";
import {AppContext} from "../../../context/context";
import {InventoryType} from "../../../shared_src/shared_types";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
//import {CharacterInventory as CharacterInventoryPermission} from "../../../shared_src/shared_types";


type CharacterInventoryType = {
    username: string,
};
const InventoryListItem = ({itemname, amount}: InventoryType) => {
    return (
        <tr>
            <td>{itemname}</td>
            <td>{amount}</td>
        </tr>
    );

};

export const CharacterInventory: FC<CharacterInventoryType> = ({username}) => {

    const {state} = useContext(AppContext);
    const [search, setSearch] = useState('');
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const inventory = character.inventory;
    if(!inventory){
        return null;
    }


    const inventoryList = inventory.map((inv: InventoryType) =>
        {
            if(inv.itemname.toLowerCase().includes(search.toLowerCase()) || search === ''){
                return (<InventoryListItem key={`${inv.itemname}_${inv.amount}`} itemname={inv.itemname} amount={inv.amount}/>)
            }
            return null;
        }
    );
    //const permission = new CharacterInventoryPermission({username: username});


    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>
                            Inventar{' '}
                            <input type="text" placeholder={"Suche"} onChange={(event) => {
                                setSearch(event.target.value);
                            }}/>
                        </Card.Title>
                        <div className={'sko-card-inventory-scroll'}>

                            <Table striped bordered={true} hover size={"sm"}>
                                <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Anzahl</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {inventoryList}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};