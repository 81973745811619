import React, { useContext } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from 'react-bootstrap/Form'
import { Type } from '../../context/ReducerTypes';
import favicon from '../../images/favicon.png';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { skoAcceptedCookiesCookie, skoAnimatedBackground } from "../../shared_src/shared_types";
import { BackgroundContext } from "../../context/BackgroundContext";
import { isMobile } from "react-device-detect";

const Navigation = () => {
    const { state, dispatch } = useContext(BackgroundContext);
    const [cookies, setCookie] = useCookies();
    const { t } = useTranslation();
    const hideBackground = cookies[skoAnimatedBackground];
    const hideVideo = (hideBackground === 'true') || state.backgroundVideo.isHidden || isMobile;
    return (
        <>
            <Navbar id={'navigation_bar'} sticky={"top"} collapseOnSelect expand="lg" className={'bg-sko-nav'} variant="dark">
                <Navbar.Brand href="/home" className=''>
                    <img
                        src={favicon}
                        width="30"
                        height="30"
                        className="d-inline-block align-content-start"
                        alt="Strafkolonie Online Icon"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className='me-auto'>
                        <Nav.Link as={Link} to="/">{t("navigation.home")}</Nav.Link>
                        <Nav.Link href="/forum">{t("navigation.forum")}</Nav.Link>
                        <Nav.Link as={Link} to="/webinterface">{t("navigation.webinterface")}</Nav.Link>
                        <Nav.Link as={Link} to="/aussenwelt">{t("navigation.outerworld")}</Nav.Link>
                        <Nav.Link as={Link} to="/artwork">{t("navigation.artwork")}</Nav.Link>
                        <Nav.Link as={Link} to="/imprint">{t("navigation.imprint")}</Nav.Link>

                    </Nav>
                    <Nav className="justify-content-end align-middle">
                        {!isMobile ?
                            <Form >
                                <Form.Check
                                    type="switch"
                                    id="backgroundVideoSwitch"
                                    className='navbar-background-switch'
                                    label={hideVideo ? t("navigation.animationOff") : t("navigation.animationOn")}
                                    checked={!hideVideo}
                                    onChange={() => {
                                        dispatch({ type: Type.VideoToggle });
                                        if (cookies[skoAcceptedCookiesCookie]) {
                                            setCookie(skoAnimatedBackground, !state.backgroundVideo.isHidden);
                                        }
                                    }}

                                />
                            </Form>
                            : undefined
                        }

                        {/*
                            <NavDropdown
                                title={state.backgroundVideo.isHidden ? 'Statischer Hintergrund' : 'Animierter Hintergrund'}
                                id="basic-nav-dropdown">
                                <NavDropdown.Item className={'dropdown-link'} onSelect={() => {
                                    dispatch({type: Type.VideoHide})
                                }}>Statischer Hintergrund</NavDropdown.Item>
                                <NavDropdown.Item onSelect={() => {
                                    dispatch({type: Type.VideoShow})
                                }}>Animierter Hintergrund</NavDropdown.Item>
                            </NavDropdown>
                            */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        </>
    );

};


export default Navigation;