import Row from "react-bootstrap/Row";
import React, { createRef, FC, RefObject, useContext, useEffect, useState } from "react";
import {
    ApiUserDataType, PermissionModel,
} from "../../../shared_src/shared_types";
import Spinner from "react-bootstrap/Spinner";
import { fetchData } from "../../../api/handler";
import { AppContext } from "../../../context/context";
import { Type } from "../../../context/ReducerTypes";
import { CharacterInventory as CharacterInventoryComponent } from "./CharacterInventory";
import { CharacterMonsterkills as CharacterMonsterkillsComponent } from "./CharacterMonsterkills";
import { CharacterDocuments as CharacterDocumentsComponent } from "./CharacterDocuments";
import { CharacterAchievements as CharacterAchievementsComponent } from "./CharacterAchievements";
import { CharacterAttributes as CharacterAttributesComponent } from "./CharacterAttributes";
import { CharacterWeaponStats as CharacterWeaponStatsComponent } from "./CharacterWeaponStats";
import { Can } from "./WebinterfacePermissions";

type CharacterAreaType = {
    username: string,
}



export const CharacterArea: FC<CharacterAreaType> = ({ username }) => {
    const { state } = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }

    const characterAttributes: PermissionModel = { __typename: 'CharacterAttributes', username: username };
    const characterWeaponStats: PermissionModel = { __typename: 'CharacterWeaponSkills', username: username };
    const characterInventory: PermissionModel = { __typename: 'CharacterInventory', username: username };
    const characterMonsterkills: PermissionModel = { __typename: 'CharacterMonsterkills', username: username };
    const characterDocuments: PermissionModel = { __typename: 'CharacterDocuments', username: username };
    const characterAchievements: PermissionModel = { __typename: 'CharacterAchievements', username: username };

    return (
        <>
            <Row className="g-0">
                <Can I='read' this={characterAttributes}>
                    <CharacterAttributesComponent username={username} />
                </Can>

                <Can I='read' this={characterWeaponStats}>
                    <CharacterWeaponStatsComponent username={username} />
                </Can>
            </Row>
            <Row className="g-0">
                <Can I='read' this={characterInventory}>
                    <CharacterInventoryComponent username={username} />
                </Can>
                <Can I='read' this={characterMonsterkills}>
                    <CharacterMonsterkillsComponent username={username} />
                </Can>
            </Row>
            <Row className="g-0">
                <Can I='read' this={characterAchievements}>
                    <CharacterAchievementsComponent username={username} />
                </Can>
            </Row>
            <Row className="g-0">
                <Can I='read' this={characterDocuments}>
                    <CharacterDocumentsComponent username={username} />
                </Can>
            </Row>
        </>

    );

};
export default CharacterArea;




const fetchUserData = async (url: string, method: string, data: any) => {
    return await fetchData('/updateUserData', 'POST', data);
};

type EditableValueType = {
    keyId: string,
    username: string,
    parentValue: any,
    target: any
};


export const EditableValue: FC<EditableValueType> = ({ keyId, username, parentValue, target }) => {
    const { state, dispatch } = useContext(AppContext);
    const value = parentValue[keyId];
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputField: RefObject<HTMLInputElement> = createRef();
    const ability = state.account.ability;

    const pressEnter = (event: React.KeyboardEvent) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            stopEditing();
        } else if (event.key === 'Escape') {
            setEditable(false);
        }
    };

    const stopEditing = async () => {
        const newValue = inputField?.current?.value;
        setEditable(false);
        if (value.toString() === newValue?.toString()) {
            return;
        }
        const data = {
            type: keyId,
            username: username,
            data: newValue,
        };
        setLoading(true);
        const userData = await fetchUserData('/updateUserData', 'POST', data);
        if (userData.success) {
            const data = (userData as ApiUserDataType).data;
            dispatch({
                type: Type.AccountSetCharacter,
                payload: data,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        inputField?.current?.focus();
        inputField?.current?.select();
    });



    return (
        <>
            <span style={{ display: "inline" }}>
                {ability.can('edit', target) ?
                    editable ?
                        <>
                            <input size={0} type="text" ref={inputField} style={{ width: '4em' }} defaultValue={value} onChange={() => {
                                if (inputField.current) {
                                    inputField.current.style.width = inputField.current.value.length + "em";
                                }
                            }} onKeyDown={pressEnter}
                                onBlur={stopEditing} />
                        </>
                        :
                        <span onDoubleClick={() => setEditable(!editable)}>
                            {value}{" "}{loading ? (
                                <Spinner as={"span"} animation="border" size="sm" variant="warning" />) : null}
                        </span>
                    :
                    parentValue[keyId]
                }
            </span>
        </>
    );
};





