import React, {FC, useContext} from "react";
import {AppContext} from "../../../context/context";
import {
    DocumentListType,
    DocumentType
} from "../../../shared_src/shared_types";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
//import {CharacterDocuments as CharacterDocumentsPermission} from "../../../shared_src/shared_types";

type CharacterDocumentsType = {
    username: string,
};
const DocumentListItem = ({id, name, paragraphs}: DocumentType) => {

    const paragraph = Object.values(paragraphs).map((paragraph,index) =>
    {
        return (
            <span key={'I'+index+'+Paragraph'+paragraph.id}>
                {paragraph.text}
                <br/>
            </span>
        );
    });

    return (
        <tr>
            <td>{id}</td>
            <td>{name}</td>
            <td>{paragraph}</td>
        </tr>
    );

};

export const CharacterDocuments: FC<CharacterDocumentsType> = ({username}) => {

    const {state} = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const documents: DocumentListType | undefined = character.documents;
    if(!documents){
        return null;
    }

    const documentsList = Object.values(documents).map((document) =>
        {
            return (<DocumentListItem key={`${document.name.trim()}_${document.id}`} id={document.id} name={document.name} paragraphs={document.paragraphs}/>)
        }
    );

    //const permission = new CharacterDocumentsPermission({username: username});

    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>Dokumente</Card.Title>
                        <div className={'sko-card-inventory-scroll'}>
                            <Table striped bordered={true} hover size={"sm"}>
                                <thead>
                                <tr>
                                    <th>Dokument ID</th>
                                    <th>Titel</th>
                                    <th>Inhalt</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {documentsList}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};