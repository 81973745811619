import React, {useContext} from 'react';
import dayClear from '../videos/background/day/clear.mp4';
import dayRain from '../videos/background/day/rain.mp4';
import dayThunder from '../videos/background/day/thunder.mp4';
import nightClear from '../videos/background/night/clear.mp4';
import nightRain from '../videos/background/night/rain.mp4';
import nightThunder from '../videos/background/night/thunder.mp4';
import {Weather} from './playerlist';
import {BackgroundContext} from "../context/BackgroundContext";
import {PlayerlistContext} from "../context/PlayerlistContext";


///const timeKey = Math.floor(Math.random()* 2) === 0 ? 'day' : 'night';
//const weatherChance = Math.floor(Math.random() * 3);
//const weatherKey = weatherChance === 0 ? 'clear' : weatherChance === 1 ? 'rain' : 'thunder';
//const list: VideoTrackList[] = [] as VideoListType;
//const list = [videoList.day.clear,videoList.day.rain,videoList.day.thunder,videoList.night.clear,videoList.night.rain,videoList.night.thunder];
//let counter = -1;

interface VideoListWeatherType {
    clear: string,
    rain: string,
    thunder: string
}

interface VideoListType {
    night: VideoListWeatherType,
    day: VideoListWeatherType
}

const videoList: VideoListType = {
    day: {} as VideoListWeatherType,
    night: {} as VideoListWeatherType
} as VideoListType;
videoList.day.clear = dayClear;
videoList.day.rain = dayRain;
videoList.day.thunder = dayThunder;
videoList.night.clear = nightClear;
videoList.night.rain = nightRain;
videoList.night.thunder = nightThunder;

const GetBackgroundVideo = (hour: number, weather: number): string => {
    const isNight = hour > 21 || hour < 5;

    if(isNight){
        switch(weather){
            case Weather.Rain:
            case Weather.HeavyRain:
                return videoList.night.rain;
            case Weather.Thunderstorm:
                return videoList.night.thunder;
            default:
                return videoList.night.clear;
        }
    }

    switch(weather){
        case Weather.Rain:
        case Weather.HeavyRain:
            return videoList.day.rain;
        case Weather.Thunderstorm:
            return videoList.day.thunder;
        default:
            return videoList.day.clear;
    }
};



const ShowBackgroundVideo = () => {
    const {state: playerlistState} = useContext(PlayerlistContext);
    const {state: backgroundState} = useContext(BackgroundContext);

    if( backgroundState.backgroundVideo.isHidden) {
        return null;
    }


    const bgVideo = GetBackgroundVideo(playerlistState.playerList.hour,playerlistState.playerList.weather);
    return <video loop autoPlay muted id={"backgroundVideo"} src={bgVideo}/>;
};

export default () => {

    return (
        <>
            <ShowBackgroundVideo/>
        </>
    );

};