import React, { createContext, useReducer, Dispatch } from "react";
import {
    playerListReducer,
    ReducerAction,
    InitialStateType,
} from "./PlayerlistReducer";

import { PlayerType } from "../shared_src/shared_types";


const initialState = {
    playerList: {
        servername: 'Strafkolonie Online',
        weather: 0,
        hour: 0,
        minute: 0,
        playerAmount: 0,
        playerMax: 0,
        playerRecord: 0,
        players: [] as PlayerType[],
        lastUpdate: Date.now(),
        isOnline: false
    },
};

const PlayerlistContext = createContext<{
    state: InitialStateType;
    dispatch: Dispatch<ReducerAction>;
}>({
    state: initialState,
    dispatch: () => null
});



const mainReducer = (
    { playerList }: InitialStateType, action: ReducerAction) => ({
        playerList: playerListReducer(playerList, action),
    });

const PlayerlistProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (
        <PlayerlistContext.Provider value={{ state, dispatch }}>
            {children}
        </PlayerlistContext.Provider>
    );
};

export { PlayerlistProvider, PlayerlistContext };
