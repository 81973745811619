import React, {FC, useContext, useState} from "react";
import {AppContext} from "../../../context/context";
import {
    MonsterkillsType
} from "../../../shared_src/shared_types";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
//import {CharacterMonsterkills as CharacterMonsterkillsPermission} from "../../../shared_src/shared_types";


type CharacterMonsterkillsType = {
    username: string,
};
const MonsterkillListItem = ({instance, amount}: MonsterkillsType) => {
    return (
        <tr>
            <td>{instance}</td>
            <td>{amount}</td>
        </tr>
    );

};

const getMonstername = (instance: string) => {
    switch(instance)
    {
        /*Blutfliegen*/
        case 'BLOODFLY':
            return 'Dunkle Blutfliege';
        case 'BLOODFLYG1':
            return 'Helle Blutfliege';
        /*Scavenger*/
        case 'SCAVENGER_DEMON':
            return 'Graslandscavenger';
        case 'YOUNGSCAVENGER_DEMON':
            return 'Junger Graslandscavenger';
        case 'YOUNGSCAVENGER':
            return 'Junger Scavenger';
        case 'SCAVENGER':
            return 'Scavenger';
        /*Snapper, Razor*/
        case 'SNAPPER':
            return 'Snapper';
        case 'BERGSNAPPER':
            return 'Bergsnapper';
        case 'RAZOR':
            return 'Razor';
        /*Schattenläufer*/
        case 'SHADOWBEAST':
            return 'Schattenläufer';
        case 'YOUNGSHADOWBEAST':
            return 'Junger Schattenläufer';
        case 'SHADOWBEAST_SKELETON':
            return 'Untoter Schattenläufer';
        /*Goblins*/
        case 'GOBBOELITE':
            return 'Goblinelite';
        case 'GOBBO_GREEN':
            return 'Goblin';
        case 'GOBBO_BLACK':
            return 'Schwarzer Goblin';
        case 'GOBBO_WARRIOR':
            return 'Goblinkrieger';
        case 'MOLERAT_GOBBO':
            return 'Goblinreiter';
        case 'MOLERAT_GOBBO_WARRIOR':
            return 'Goblinkriegsreiter';
        case 'GOBBOHAEUPTLING':
            return 'Goblinhäuptling';
        /*Wölfe, Warge, Orkhunde etc*/
        case 'BLACKWOLF':
            return 'Schwarzer Wolf';
        case 'WOLF':
            return 'Wolf';
        case 'YWOLF':
            return 'Junger Wolf';
        case 'WARG':
            return 'Warg';
        case 'ICEWOLF':
            return 'Weißer Wolf';
        case 'ORKHUND_NEU':
            return 'Orkhund';

        /*Orks*/
        case 'ORCSHAMAN_SIT':
            return 'Orkschamane';
        case 'UndeadOrcWarrior':
            return 'Untoter Orkkrieger';
        case 'ORCWARRIOR_ROAM':
        case 'ORCWARRIOR_HARAD':
            return 'Orkkrieger';
        case 'ORCSCOUT':
            return 'Orkspäher';
        case 'ORCELITE_ROAM':
            return 'Orkelite';
        /*Untote*/
        case 'SKELETON':
            return 'Skelett';
        case 'SKELETON_LORD':
            return 'Skelettkrieger';
        case 'LESSER_SKELETON':
            return 'Niederes Skelett';
        case 'GARDESKELETON':
            return 'Gardeskelett';
        case 'GURUSKELETON':
            return 'Guruskelett';
        case 'ZOMBIE01':
        case 'ZOMBIE02':
        case 'ZOMBIE03':
        case 'ZOMBIE04':
            return 'Zombie';
        case 'GOBBO_SKELETON':
            return 'Goblinskelett';
        /*Warane*/
        case 'FIREWARAN':
            return 'Feuerwaran';
        case 'WARAN':
            return 'Waran';
        /*Crawler*/
        case 'CRWQUEEN':
            return 'Minecrawler-Königin';
        case 'MINECRAWLERWARRIOR':
            return 'Minecrawler-Warrior';
        case 'MINECRAWLER':
            return 'Minecrawler';
        /*Trolle*/
        case 'YOUNGTROLL':
            return 'Junger Troll';
        case 'TROLL':
            return 'Troll';
        case 'BLACKTROLL':
            return 'Schwarzer Troll';
        /*Molerats*/
        case 'MOLERAT':
            return 'Molerat';
        case 'YOUNGMOLERAT':
            return 'Junges Molerat';
        /*Sonstiges*/
        case 'LURKER':
            return 'Lurker';
        case 'ORCBITER':
            return 'Orkbeisser';
        case 'GIANT_RAT':
            return 'Riesenratte';
        case 'BLOODHOUND':
            return 'Bluthund';
        case 'MEATBUG':
            return 'Fleischwanze';
        case 'KEILER':
            return 'Keiler';
        case 'HARPIE':
            return 'Harpie';
        case 'KANINCHEN':
            return 'Kaninchen';
        case 'SWAMPDRONE':
            return 'Sumpfgasdrone';
        case 'SWAMPSHARK':
            return 'Sumpfhai';
        case 'GIANT_BUG':
            return 'Feldräuber';
        case 'ALLIGATOR':
            return 'Alligator';
        case 'PC_HERO':
            return 'NPC-Wache';
        case 'STONEGOLEM':
            return 'Steingolem';
        case 'FIREGOLEM':
            return 'Feuergolem';
        case 'SWAMPGOLEM':
            return 'Sumpfgolem';
        case 'ICEGOLEM':
            return 'Eisgolem';
        case 'ERZGOLEM':
            return 'Erzgolem';
        case 'SWAMPRAT':
            return 'Sumpfratte';
        default:
            return instance;
    }
}

export const CharacterMonsterkills: FC<CharacterMonsterkillsType> = ({username}) => {

    const {state} = useContext(AppContext);
    const [search, setSearch] = useState('');
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const monsterkills = character.monsterkills;
    if(!monsterkills){
        return null;
    }

    //const permission = new CharacterMonsterkillsPermission({username: username});


    const monsterkillList = monsterkills.map((monsterkill: MonsterkillsType) =>
        {
            const monstername = getMonstername(monsterkill.instance);
            if(monstername.toLowerCase().includes(search.toLowerCase()) || search === ''){
                return (<MonsterkillListItem key={`${monsterkill.instance}_${monsterkill.amount}`} instance={monstername} amount={monsterkill.amount}/>)
            }
            return null;
        }
    );


    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>
                            Monsterkills{' '}
                            <input type="text" placeholder={"Suche"} onChange={(event) => {
                                setSearch(event.target.value);
                            }}/>
                        </Card.Title>
                        <div className={'sko-card-inventory-scroll'}>

                            <Table striped bordered={true} hover size={"sm"}>
                                <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Anzahl</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {monsterkillList}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};