
export type InventoryType = {
    itemname: string,
    amount: number,
};
export type MonsterkillsType = {
    instance: string,
    amount: number,
};
export type AchievementType = {
    name: string,
    description: string,
    date: Date,
};

export type DocumentListType = {
    [id: number]: DocumentType,
};

export type DocumentType = {
    id: number,
    name: string,
    paragraphs: DocumentParagraphListType,
};

export type DocumentParagraphListType = {
    [id: number]: DocumentParagraphType,
};

export type DocumentParagraphType = {
    id: number,
    text: string,
};

export type AttributeType = {
    strength: number,
    dexterity: number,
    hp: number,
    maxhp: number,
    mana: number,
    maxmana: number,
    magic: number
};

export type WeaponSkillType = {
    onehandedSword: number,
    onehandedAxe: number,
    twohandedSword: number,
    twohandedAxe: number,
    bow: number,
    crossbow: number,
};

export type SkillType = {
    sneaking: boolean,
    acrobatics: boolean,
    lockpicking: boolean,
    pickpocket: boolean,
};

export type CharacterVisualType = {
    instance: string,
    gender: string,
    bodyTextureId: number,
    headMesh: string,
    faceTextureId: number,
    fatness: number,
    shoulderWidth: number
};

export type UserLoginType = {
    userData: UserDataType,
    permissionData: any[],
};

export type UserDataType = {
    username: string,
    lastLogin: Date,
    firstLogin: Date,
    facechange: boolean,
    attributes?: AttributeType,
    weaponSkill?: WeaponSkillType,
    skill?: SkillType,
    characterVisual?: CharacterVisualType,
    inventory?: InventoryType[],
    monsterkills?: MonsterkillsType[],
    achievements?: AchievementType[],
    documents?: DocumentListType,
};

export type PlayerType = {
    playerId: number;
    playername: string;
    status: number;
};

export type ServerStatsDataType = {
    servername: string,
    weather: number,
    hour: number,
    minute: number,
    playerAmount: number,
    playerMax: number,
    playerRecord: number,
    players: PlayerType[],
    lastUpdate: number,
    isOnline: boolean
};

type ErrorType = {
    code: number,
    message: string,
};

export type ApiJSONType = {
    success: boolean;
    error?: ErrorType;
    data?: any;
};

export interface ApiPlayerListType extends ApiJSONType {
    data?: null | ServerStatsDataType,
}

export interface ApiUserDataType extends ApiJSONType {
    data?: null | UserDataType,
}
export interface ApiLoginDataType extends ApiJSONType {
    data?: null | UserLoginType,
}

export type UserCredentialsType = {
    username: string,
    roles: string[],
    logoutDate: number,
};


export const skoPermissionsCookie = 'sko_jwt';
export const skoAcceptedCookiesCookie = 'sko_allow_cookies';
export const skoAnimatedBackground = 'sko_animated_background_hidden';



class Entity {
    constructor(props: any) {
        Object.assign(this, props);
    }
}

export class AccountData extends Entity { }
export class CharacterData extends Entity { }
export class CharacterAttributes extends Entity { }
export class CharacterWeaponSkills extends Entity { }
export class CharacterInventory extends Entity { }
export class CharacterDocuments extends Entity { }
export class CharacterMonsterkills extends Entity { }
export class CharacterAchievements extends Entity { }
export class CharacterVisualDetails extends Entity { }
export class CharacterAdminLevel extends Entity { }
export class LogArea extends Entity { }
export class AdminArea extends Entity { }
export class AdminLivemap extends Entity { }
export class AdminServerStatistics extends Entity { }

export interface PermissionModel {
    __typename: PermissionSubject,
    username: string,
    [props: string]: any
}


export type PermissionAction = 'create' | 'read' | 'edit' | 'delete' | 'see' | 'access';
export type PermissionSubject = (typeof
    AccountData | CharacterData | CharacterAttributes | CharacterWeaponSkills |
    CharacterInventory | CharacterDocuments | CharacterMonsterkills | CharacterAchievements
    | CharacterVisualDetails | LogArea | AdminArea | AdminLivemap | CharacterAdminLevel | AdminServerStatistics) |
    ('AccountData' | 'CharacterData' | 'CharacterAttributes' | 'CharacterWeaponSkills' |
        'CharacterInventory' | 'CharacterDocuments' | 'CharacterMonsterkills' | 'CharacterAchievements'
        | 'CharacterVisualDetails' | 'LogArea' | 'AdminArea' | 'AdminLivemap' | 'AdminServerStatistics' | 'CharacterAdminLevel');

export type PermissionAbilities = [PermissionAction, PermissionSubject];
