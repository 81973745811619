

export enum Type {
    AccountLogin = 'ACCOUNT_LOGIN',
    AccountLogout = 'ACCOUNT_LOGOUT',
    AccountStopCheckLogin = 'ACCOUNT_STOPCHECKLOGIN',
    AccountSetCharacter = 'ACCOUNT_SETCHARACTER',
    PlayerListReset = 'PLAYERLIST_RESET',
    PlayerListRefresh = 'PLAYERLIST_REFRESH',
    VideoShow = 'VIDEO.SHOW',
    VideoHide = 'VIDEO.HIDE',
    VideoToggle = 'VIDEO_TOGGLE',
    VideoSet = 'VIDEO_SET',
}