import React, {FC, useContext} from "react";
import {AppContext} from "../../../context/context";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {EditableValue} from "./CharacterArea";
import {PermissionModel} from "../../../shared_src/shared_types";

type CharacterWeaponStatsType = {
    username: string
};

export const CharacterWeaponStats: FC<CharacterWeaponStatsType> = ({username}) => {
    const {state} = useContext(AppContext);
    if (!state.account.characters || !state.account.characters[username]) {
        return null;
    }
    const character = state.account.characters[username];
    const weaponSkills = character.weaponSkill;
    if(!weaponSkills){
        return null;
    }

    const permission: PermissionModel = {__typename: 'CharacterWeaponSkills', username: username};

    return (
        <>
            <Col>
                <Card className={'bg-sko sko-card-height-full'}>
                    <Card.Body>
                        <Card.Title>Waffentalente</Card.Title>
                        <Card.Text>
                            Einhandschwert: <EditableValue target={permission} parentValue={weaponSkills} keyId={"onehandedSword"} username={username}/><br/>
                            Einhandaxt: <EditableValue target={permission} parentValue={weaponSkills} keyId={"onehandedAxe"} username={username}/><br/>
                            Zweihandschwert: <EditableValue target={permission} parentValue={weaponSkills} keyId={"twohandedSword"} username={username}/><br/>
                            Zweihandaxt: <EditableValue target={permission} parentValue={weaponSkills} keyId={"twohandedAxe"} username={username}/><br/>
                            Stabkampf: {Math.max(weaponSkills?.twohandedSword as number, weaponSkills?.twohandedAxe as number)}<br/>
                            Armbrust: <EditableValue target={permission} parentValue={weaponSkills} keyId={"crossbow"} username={username}/><br/>
                            Bogen: <EditableValue target={permission} parentValue={weaponSkills} keyId={"bow"} username={username}/><br/>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};
