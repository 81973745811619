import React, { FC, useEffect } from "react";

import { fetchData } from "../../../api/handler";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const dotSize = 6;
const TO_RADIANS = Math.PI / 180;
const newCampColor = '#6495ED';
const oldCampColor = '#ff3f3f';
const swampCampColor = '#66CD00';

type PlayerCoordinates = {
    x: number,
    y: number,
    z: number
};

type Dot = {
    x: number,
    y: number,
    hovercolor: string,
    blurcolor: string,
    isHovering: boolean
};

class Player {
    static list: Player[] = [];
    static id: number = 0;

    public id: number;
    public name: string;
    public coordinates: PlayerCoordinates;
    public a: number;
    public lastCoordinates: PlayerCoordinates[];
    public camp: string;
    public hp: number;
    public maxhp: number;
    public offline: boolean;
    public dot: Dot;

    constructor(name: string, x: number, y: number, z: number, a: number, camp: string, hp: number, maxhp: number) {
        this.id = Player.id++;
        this.name = name;
        this.coordinates = { x: x, y: y, z: z };
        this.a = a;
        this.lastCoordinates = [];
        this.lastCoordinates.push(this.coordinates);
        this.camp = camp;
        this.hp = hp;
        this.maxhp = maxhp;
        this.offline = true;

        let hcolor = 'yellow';
        let bcolor = 'white';

        if (this.camp === 'Altes Lager') {
            bcolor = oldCampColor;
        }
        else if (this.camp === 'Neues Lager') {
            bcolor = newCampColor;
        }
        else if (this.camp === 'Sumpflager') {
            bcolor = swampCampColor;
        }
        else if (this.camp === 'Wegelagerer') {
            bcolor = 'brown';
        }

        this.dot = {
            x: x,
            y: y,
            hovercolor: hcolor,
            blurcolor: bcolor,
            isHovering: false
        };
        Player.list.push(this);
    }

    setCoordinates(x: number, y: number, z: number) {
        this.coordinates.x = x;
        this.coordinates.y = y;
        this.coordinates.z = z;
        this.dot.x = x;
        this.dot.y = y;
    }

    remove() {
        const index = Player.list.indexOf(this);
        if (index > -1) {
            Player.list.splice(index, 1);
        }
    }

    static getList() {
        return Player.list;
    }

}


export const AdminLivemap = () => {
    useEffect(() => {
        const canvas: HTMLCanvasElement | null = document.getElementById('sko-livemap') as HTMLCanvasElement;
        if (!canvas) {
            return undefined;
        }
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (!context) {
            return undefined;
        }


        const xPerPixel = 195 / 2;
        const zPerPixel = 165.7142 / 2;
        //const mapXMid = 800; //1600/2 = 800
        const mapXMid = canvas.width / 2; //1600/2 = 800
        //const mapZMid = 600; //1200/2 = 600
        const mapZMid = canvas.height / 2; //1200/2 = 600

        let clientX = 0;
        let clientY = 0;
        let offsetX = 0;
        let offsetY = 0;

        const loadPlayers = () => {
            canvas.onmousemove = (e: MouseEvent) => {
                drawPlayers(e);
            };
        };

        const drawPlayers = (e: MouseEvent | null = null) => {
            let i = 0;
            context.clearRect(0, 0, canvas.width, canvas.height); //clear content
            const playerList = Player.getList();
            let playersHovered = [];
            let player;

            if (e) {
                clientX = e.clientX;
                clientY = e.clientY;
                offsetX = e.offsetX;
                offsetY = e.offsetY;
            }

            while ((player = playerList[i++])) {
                const x = mapXMid + (player.coordinates.x / xPerPixel);
                const z = mapZMid - (player.coordinates.z / zPerPixel);
                const dot = player.dot;
                context.beginPath();
                const circle = new Path2D();
                circle.arc(x, z, dotSize, 0, 2 * Math.PI);

                if (e) {
                    dot.isHovering = context.isPointInPath(circle, offsetX, offsetY);
                }
                context.fillStyle = dot.isHovering ? dot.hovercolor : dot.blurcolor;
                context.fill(circle);
                if (dot.isHovering) {
                    playersHovered.push(player);
                }

                context.save();
                context.translate(x, z);
                context.rotate(TO_RADIANS * -135);
                context.rotate(TO_RADIANS * player.a);
                const border = new Path2D();
                context.beginPath();
                border.arc(0, 0, dotSize, 0, 0.5 * Math.PI);
                context.strokeStyle = dot.isHovering ? dot.hovercolor : dot.blurcolor;
                context.lineWidth = 4.5;
                context.stroke(border);
                context.strokeStyle = '#000';
                context.lineWidth = 1.0;
                context.stroke(border);

                context.closePath();
                context.restore();

            }

            const rect = canvas.getBoundingClientRect();
            let x2 = (clientX - rect.left) / (rect.right - rect.left) * canvas.width;
            let y2 = (clientY - rect.top) / (rect.bottom - rect.top) * canvas.height;
            playersHovered.forEach(hoveredPlayer => {
                if (hoveredPlayer.dot.isHovering) {
                    const str = `Name: ${hoveredPlayer.name} , ${hoveredPlayer.hp}/${hoveredPlayer.maxhp} , Lager: ${hoveredPlayer.camp}`;
                    context.fillStyle = 'black';
                    context.fillRect(x2 + 10, y2 + 10, 320, 25);
                    context.fillStyle = hoveredPlayer.dot.blurcolor;
                    context.font = 'bold 20px verdana';
                    context.fillText(str, x2 + 20, y2 + 30, 250);
                    y2 += 25;
                }
            });
        };


        const fetchPlayerData = async () => {
            const livemapData = await fetchData('/livemap', 'GET');
            if (livemapData.success) {
                const data = livemapData.data;
                let newPlayerList: Player[] = [];
                data.forEach((e: any) => {
                    let found = false;
                    for (let i = 0; i < Player.list.length; i++) {
                        const player = Player.list[i];
                        if (player.name === e['name']) {
                            player.setCoordinates(e['x'], e['y'], e['z']);
                            player.a = e['a'];
                            player.camp = e['lager'];
                            player.hp = e['hp'];
                            player.maxhp = e['maxhp'];
                            newPlayerList.push(player);
                            found = true;
                        }
                    }
                    if (!found) {
                        const p = new Player(e['name'], e['x'], e['y'], e['z'], e['a'], e['lager'], e['hp'], e['maxhp']);
                        newPlayerList.push(p);
                    }
                });
                Player.list = newPlayerList;
                drawPlayers();
            }

        };

        const refreshTimer = setInterval(() => {
            fetchPlayerData();
        }, 1000);

        loadPlayers();
        fetchPlayerData();

        return () => {
            clearInterval(refreshTimer);
        }
    }, []);



    return (
        <>
            <canvas id="sko-livemap" height="1200" width="1600" style={{ backgroundImage: 'url(https://strafkolonie-online.net/forum/skoLivemap/gothicMap.jpg)' }} />
        </>
    );
};

type ModalType = {
    onHide: any,
    show: boolean,
};

export const AdminLivemapModal: FC<ModalType> = (props) => {

    return (
        <Modal
            {...props}
            centered
            className={"bg-sko-modal"}
            dialogClassName={"bg-sko-modal-90w"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Livemap
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AdminLivemap />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}