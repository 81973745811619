import {Type} from "./ReducerTypes";

export type VideoType = {
    isHidden: boolean,
    time: 'day' | 'night',
    weather: 'clear' | 'rain' | 'thunder',
};

export type InitialStateType = {
    backgroundVideo: VideoType;
};

export type ReducerAction = {
    type: string,
    payload?: any | any[]
}



export const videoReducer = (state: VideoType, action: ReducerAction) => {
    switch (action.type) {
        case Type.VideoHide:
            return {...state, isHidden: true};
        case Type.VideoShow:
            return {...state, isHidden: false};
        case Type.VideoToggle:
            return {...state, isHidden: !state.isHidden};
        case Type.VideoSet:
            return {...state, time: action.payload.day, weather: action.payload.weather};
        default:
            return state;
    }
};
