import React, { useContext, useState } from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { AppContext } from "../context/context";
import { fetchData } from "../api/handler";
import { Type } from "../context/ReducerTypes";

const fetchLogout = () => {
    return fetchData('/logout');
};

export default () => {
    const { dispatch } = useContext(AppContext);

    const [logginOut, setLogginOut] = useState(false);

    return (
        <>
            <Card.Body>
                <Card.Title>Logout</Card.Title>
                <Form>
                    <Button type="button" disabled={logginOut} onClick={async () => {
                        setLogginOut(true);
                        const res = await fetchLogout();
                        setLogginOut(false);
                        if (!res.error && res.success) {
                            dispatch({
                                type: Type.AccountLogout
                            })
                        }
                    }}>
                        {!logginOut ?
                            <>Abmelden</> :
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Du wirst ausgeloggt..
                            </>
                        }
                    </Button>
                </Form>
            </Card.Body>
        </>
    );
};

