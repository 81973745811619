export const toDateString = (d: Date | undefined): string => {
    if (!d) {
        return 'Invalid date';
    }
    d = new Date(d);

    const dHours = d.getHours();
    const dMinutes = d.getMinutes();
    const dDays = d.getDate();
    const dMonth = d.getMonth() + 1;
    const year = d.getFullYear();

    const minute: string = dMinutes < 10 ? '0' + dMinutes : dMinutes.toString();
    const hour: string = dHours < 10 ? '0' + dHours : dHours.toString();
    const days: string = dDays < 10 ? '0' + dDays : dDays.toString();
    const month: string = dMonth < 10 ? '0' + dMonth : dMonth.toString();

    return `${days}.${month}.${year} ${hour}:${minute} Uhr`;
};

export default toDateString;