import React from 'react';
import Container from "react-bootstrap/Container";
import { Trans} from 'react-i18next';


const currentYear = new Date().getFullYear();

const Footer = () => {

    return (
        <>
            <Container fluid as={'footer'} id={'footer'} className={'bg-sko-footer text-center'}>
                <br/>
                <p>
                    <Trans i18nKey="footer.copyright" values={
                        {
                            year: currentYear
                        }
                    }/>
                </p>
                <p>
                    <Trans i18nKey="footer.pbcopyright"/>
                </p>
                <br/>
            </Container>
        </>
    );

};


export default Footer;