import './wdyr';
import React, { useContext, useEffect } from 'react';
import './SCSS/App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/layouts/header';
import Footer from './components/layouts/footer';
import Navigation from './components/layouts/navigation';
import Content from './components/content';
import { BrowserRouter, HashRouter } from "react-router-dom";
import { AppContext } from "./context/context";
import { fetchData } from "./api/handler";
import { Type } from "./context/ReducerTypes";

import { ApiLoginDataType, skoAcceptedCookiesCookie } from './shared_src/shared_types';
import CookieConsent from "react-cookie-consent";
import { useCookies } from 'react-cookie';
import './i18n';

const App = () => {

    const { dispatch } = useContext(AppContext);

    useEffect(() => {
        const fetchCheckLogin = async () => {
            const loginCheck: ApiLoginDataType = await fetchData('/checkLogin');
            if (loginCheck.success) {
                const data = loginCheck.data;
                dispatch({ type: Type.AccountLogin, payload: data });
            } else {
                dispatch({ type: Type.AccountStopCheckLogin })
            }
        };
        fetchCheckLogin();
    }, [dispatch]);

    return (
        <>
            <Container fluid>
                <Row className={"justify-content-center"}>
                    <Col md={{ span: 9 }}>
                        <HashRouter >
                            <Header />
                            <div className={'bg-sko-container'}>
                                <Navigation />
                                <Content />
                                <Footer />
                            </div>
                            <AskCookieConsent />
                        </HashRouter >
                    </Col>
                </Row>
            </Container>
        </>
    );

};


const AskCookieConsent = () => {

    const [cookies, setCookie] = useCookies();
    if (cookies[skoAcceptedCookiesCookie]) {
        return null;
    }
    const cookieUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_COOKIE_URL : process.env.REACT_APP_BACKEND_COOKIE_DEV_URL;
    return (
        <CookieConsent
            location="bottom"
            buttonText="Zustimmen"
            declineButtonText="Ablehnen"
            enableDeclineButton={false}
            containerClasses={'bg-sko-cookie'}
            buttonClasses={'btn'}
            disableButtonStyles={true}
            declineButtonClasses={'btn'}
            buttonStyle={{
                marginLeft: "1em",
                marginRight: "5em",
                background: "#2B373B"
            }}
            cookieName={skoAcceptedCookiesCookie}
            expires={365}
            extraCookieOptions={{
                //maxAge: 60 * 60 * 24 * 365,
                sameSite: 'none',
                secure: true,
                path: '/',
                domain: cookieUrl
            }}
            hideOnAccept={true}

        >
            <span className={'danger'}>Achtung: </span>Diese Seite ben&ouml;tigt Cookies. Um diese Seite vollst&auml;ndig nutzen
            zu können, müssen Sie der Nutzung von Cookies zustimmen.{" "}
        </CookieConsent>

    );

}

export default App;
