import React from "react";


export const LogManagementArea = () => {

    return (
        <>
        </>
    );

};

export default LogManagementArea;